@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Stile iniziale: elemento nascosto */
.tendina-hidden {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}

/* Stile per mostrare l'elemento */
.tendina-visible {
  max-height: 500px; /* Imposta un valore massimo sufficiente per contenere il contenuto */
  transition: max-height 0.5s ease-in;
}




