* {
  box-sizing: border-box;
}

section {
  position: relative;
  font-family: "Fira Sans", Helvetica, Arial, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  margin: 0 20px;
  color: #6e6e6e;
  margin: 0px auto;
  padding-top: 0;
  max-width: 1000px;
  border-top: 2px solid transparent;
  text-align: left;
}

section.demo {
  margin-top: 30px;
}

section h4 {
  margin: 30px 0 15px 0;
  font-weight: 500;
  font-size: 20px;
  color: #3e3e3e;
}

@media (max-width: 600px) {
  section.demo {
    border-top: 0;
  }
}

.section-title {
  color: #3e3e3e;
  font-size: 26px;
  font-weight: 700;
  margin: 30px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}

.section-title > span {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.section-title > span:after {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 0;
  right: 10px;
  height: 2px;
  background: #3e3e3e;
}

/* Item style */

.muuri-item {
  z-index: 1;
  padding-top: 30%;
}

/* Stile per gli item */
.item-content {
  padding: 0;               /* Rimuovi il padding per non avere spazio interno */
  display: flex;            /* Rendi il contenitore un flex per controllare il posizionamento, ma senza contenuto */
  flex-wrap: wrap;        
  align-items: center;      /* Allinea gli eventuali contenuti centrati, ma nel caso di un box vuoto non farà differenza */
  justify-content: center;  /* Posiziona eventuali contenuti al centro, ma vuoto per ora */
  box-shadow: none;         /* Rimuovi la shadow */
}

.content-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;

  transition: 0.2s;
}

.column-item .item-content {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-style: solid;
  border-width: 1.3px;
}

.column-item .content-header {
  border: 0;
  border-style: solid;
  border-bottom-width: 1px;
  height: 18px;
}

.column-item .item-content,
.dashboard-item .item-content {
  /* Se vuoi mantenere il bordo arrotondato specifico anche per questi tipi di contenitori */
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;  /* Imposta un bordo più visibile se necessario */
  border-color: #ddd; /* Usa un colore di bordo più chiaro */
}

.item-content.blue {
  border-color: #4088d5;
}

.item-content.blue .content-header {
  background: #4a9ff9;
  border-bottom-color: #4088d5;
}

.item-content.orange {
  border-color: #d98140;
}

.item-content.orange .content-header {
  background: #f9944a;
  border-bottom-color: #d98140;
}

.item-content.green {
  border-color: #22a45c;
}

.item-content.green .content-header {
  background: #2ac06d;
  border-bottom-color: #22a45c;
}

/* Item-event style */

.muuri-item-positioning {
  z-index: 2;
}

.muuri-item-dragging,
.muuri-item-releasing {
  z-index: 3;
}

.muuri-item-dragging {
  cursor: move;
}

.muuri-item-hidden {
  z-index: 0;
}

.muuri-item-placeholder {
  z-index: 2;
  margin: 0;
  opacity: 0.5;
}

/* Containers style */

.muuri {
  min-height: 100%;
}

/* Layout principale */
.Main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Spazio tra le dashboard */
}
.grid-demo{
  margin: 0;
  
}

section {
  max-width: 100%;
}

/* Card */
.card {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.card-title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}




.column {
  background: #ececec;
  padding-top: 10px;
  border: 3px solid #ddd;
  border-right: 0;
  overflow-y: scroll;
  overflow-x: none;
}

.dashboard {
  padding: 10px;
  border: 2px solid #ddd;
}

/* Scrollbar style */

::-webkit-scrollbar {
  width: 7px;
  background-color: #ececec;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}
