
.border {
    border: 1px solid lightgray;
}

.border_previous {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.border_page {
    border: 1px solid lightgray;
}

.border_next {
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}