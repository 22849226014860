
.border {
    border: 1px solid rgb(255 255 255 / 0.1);
}

.border_light {
    border-width: 1px;
    border-color: rgb(233 237 246);
}

.activeColor {
    color: #B59CEB
}

.hr_dark {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid #252729;
}

.hr_light {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 3px solid #B59CEB;
}

.text_alert {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}

.text_alert_bold {
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}

.grab_container {
    cursor: grab;
}

.grab_container:active {
    cursor: grabbing;
}